
a {
  text-decoration: none;
}

.navbar-right {
  margin-right: 0;
}


#toast-container > div {
  opacity: 1;
}

bs-dropdown-container {
  z-index: 1;
}


.slim-loading-bar-progress {
  transition: opacity 1s ease-in;
  transition-delay: 1s;
  transition-property: opacity;
}

element.style {
  width: 100px;
}

/*
Creating btn-tertiary until bootstrap supports it our of the box.
 */
/*
This is mostly btn-light
*/
.btn-tertiary, [data-bs-theme=light] .btn-tertiary {
  --bs-btn-color: #000;
  --bs-btn-bg: var(--bs-body-bg);
  --bs-btn-border-color: var(--bs-border-color);
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

ng-icon {
  vertical-align: text-top;
  font-size: 1.15em
}

ng-icon svg {
  vertical-align: unset;
  --ng-icon__stroke-width: 40;
}

.rounded-start-bottom {
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-top {
  border-top-left-radius: var(--bs-border-radius) !important;
}


/*
This is mostly btn-dark + border fix
*/
[data-bs-theme=dark] .btn-tertiary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-body-bg);
  --bs-btn-border-color: var(--bs-border-color);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
  /* border: var(--bs-border-width) solid var(--bs-border-color);*/
}


